<svg
  width="24px"
  height="24px"
  viewBox="0 0 24 24"
  fill="none"
  {...$$props}
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M4 5V19C4 19.5523 4.44772 20 5 20H19"
    stroke="currentColor"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M18 9L13 13.9999L10.5 11.4998L7 14.9998"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>
