<svg
  width="20"
  height="20"
  viewBox="0 0 20 20"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  {...$$props}
>
  <path
    d="M14.6663 4.00009L13.4997 2.8334C13.3439 2.68072 13.1345 2.59521 12.9163 2.59521C12.6982 2.59521 12.4888 2.68072 12.333 2.8334L3.33301 11.8334V14.1668H5.66633L14.6663 5.16678C14.819 5.011 14.9046 4.80156 14.9046 4.58343C14.9046 4.36531 14.819 4.15587 14.6663 4.00009Z"
    stroke="white"
    stroke-width="1.2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M2.5 17.5H17.5"
    stroke="white"
    stroke-width="1.2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M13.2496 6.66682L10.833 4.3335"
    stroke="white"
    stroke-width="1.2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>
