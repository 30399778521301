<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  {...$$props}
>
  <path
    d="M19.9991 11.8115H16.7992C16.476 11.8329 16.1715 11.9741 15.9425 12.2085C15.7135 12.4429 15.5756 12.7546 15.5547 13.0854V14.1772C15.5547 14.3444 15.5869 14.51 15.6494 14.6645C15.712 14.8191 15.8036 14.9596 15.9192 15.0779C16.0347 15.1962 16.1719 15.29 16.3229 15.354C16.4739 15.418 16.6357 15.4508 16.7992 15.4508H19.9991"
    stroke="#AAAAAA"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M18.2222 7.26221H5.77778C4.79594 7.26221 4 8.0769 4 9.08187V18.1802C4 19.1851 4.79594 19.9998 5.77778 19.9998H18.2222C19.2041 19.9998 20 19.1851 20 18.1802V9.08187C20 8.0769 19.2041 7.26221 18.2222 7.26221Z"
    stroke="#AAAAAA"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M13.7771 7.26237L10.8439 4.25989C10.6777 4.09318 10.4542 4 10.2216 4C9.9889 4 9.76555 4.09318 9.59939 4.25989L6.66602 7.26237"
    stroke="#AAAAAA"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M12 5.44271L13.1556 4.25989C13.3218 4.09318 13.5451 4 13.7778 4C14.0104 4 14.2339 4.09318 14.4001 4.25989L17.3333 7.26237"
    stroke="#AAAAAA"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>
