<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  {...$$props}
>
  <path
    d="M9.57141 6.00024L7 8.51173L9.57141 11.0234"
    stroke="white"
    stroke-width="1.2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M17 8.51172H7"
    stroke="white"
    stroke-width="1.2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M11.2859 18L13.8573 15.4883L11.2859 12.9769"
    stroke="white"
    stroke-width="1.2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M7 15.4883H13.4286"
    stroke="white"
    stroke-width="1.2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M16 18V17.3361C16 16.9272 16.0657 16.591 16.1972 16.3277C16.3287 16.0644 16.5155 15.8515 16.7574 15.6891C17.0046 15.521 17.3018 15.381 17.6489 15.2689L18.1302 15.1092C18.3458 15.0364 18.5299 14.9468 18.6824 14.8403C18.8402 14.7339 18.9612 14.6022 19.0454 14.4454C19.1295 14.2829 19.1716 14.0952 19.1716 13.8824V13.8487C19.1716 13.5182 19.0664 13.2605 18.856 13.0756C18.6456 12.8852 18.3695 12.7899 18.0276 12.7899C17.6752 12.7899 17.3886 12.8908 17.1677 13.0924C16.952 13.2941 16.8442 13.591 16.8442 13.9832V14.1176H16.0237V13.9916C16.0237 13.5658 16.1105 13.2073 16.284 12.916C16.4576 12.619 16.6969 12.3922 17.002 12.2353C17.307 12.0784 17.6489 12 18.0276 12C18.4063 12 18.7429 12.0756 19.0375 12.2269C19.3373 12.3782 19.5713 12.591 19.7396 12.8655C19.9132 13.1401 20 13.4622 20 13.8319V13.9076C20 14.2885 19.929 14.605 19.787 14.8571C19.645 15.1092 19.4477 15.3165 19.1953 15.479C18.9481 15.6415 18.6614 15.7787 18.3353 15.8908L17.8698 16.042C17.6226 16.1204 17.4227 16.2073 17.2702 16.3025C17.1229 16.3922 17.0125 16.5014 16.9389 16.6303C16.8705 16.7591 16.8363 16.9244 16.8363 17.1261V17.2101H19.9527V18H16Z"
    fill="white"
  />
</svg>
