<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  {...$$props}
>
  <path
    d="M7.24664 13.4656L11.3722 9.25191C11.4619 9.1603 11.559 9.09557 11.6637 9.05771C11.7683 9.01985 11.8804 9.00061 12 9C12.1196 9 12.2317 9.01924 12.3363 9.05771C12.441 9.09618 12.5381 9.16092 12.6278 9.25191L16.7534 13.4656C16.9178 13.6336 17 13.8473 17 14.1069C17 14.3664 16.9178 14.5802 16.7534 14.7481C16.5889 14.916 16.3797 15 16.1256 15C15.8715 15 15.6622 14.916 15.4978 14.7481L12 11.1756L8.50224 14.7481C8.33782 14.916 8.12855 15 7.87444 15C7.62033 15 7.41106 14.916 7.24664 14.7481C7.08221 14.5802 7 14.3664 7 14.1069C7 13.8473 7.08221 13.6336 7.24664 13.4656Z"
    fill="white"
  />
</svg>
