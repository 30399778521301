<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  {...$$props}
>
  <path
    d="M10.5344 7.24664L14.7481 11.3722C14.8397 11.4619 14.9044 11.559 14.9423 11.6637C14.9802 11.7683 14.9994 11.8804 15 12C15 12.1196 14.9808 12.2317 14.9423 12.3363C14.9038 12.441 14.8391 12.5381 14.7481 12.6278L10.5344 16.7534C10.3664 16.9178 10.1527 17 9.89313 17C9.63359 17 9.41985 16.9178 9.25191 16.7534C9.08397 16.5889 9 16.3797 9 16.1256C9 15.8715 9.08397 15.6622 9.25191 15.4978L12.8244 12L9.25191 8.50224C9.08397 8.33782 9 8.12855 9 7.87444C9 7.62033 9.08397 7.41106 9.25191 7.24664C9.41985 7.08221 9.63359 7 9.89313 7C10.1527 7 10.3664 7.08221 10.5344 7.24664Z"
    fill="currentColor"
  />
</svg>
