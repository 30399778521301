<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  {...$$props}
>
  <path
    d="M9.57141 6L7 8.51153L9.57141 11.0232"
    stroke="white"
    stroke-width="1.24"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M17 8.51154H7"
    stroke="white"
    stroke-width="1.24"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M14.4285 18L16.9999 15.4883L14.4285 12.9767"
    stroke="white"
    stroke-width="1.24"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M7 15.4883H17"
    stroke="white"
    stroke-width="1.24"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>
