<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  {...$$props}
>
  <path
    d="M16.7534 10.5344L12.6278 14.7481C12.5381 14.8397 12.441 14.9044 12.3363 14.9423C12.2317 14.9802 12.1196 14.9994 12 15C11.8804 15 11.7683 14.9808 11.6637 14.9423C11.559 14.9038 11.4619 14.8391 11.3722 14.7481L7.24664 10.5344C7.08221 10.3664 7 10.1527 7 9.89313C7 9.63359 7.08221 9.41985 7.24664 9.25191C7.41106 9.08397 7.62033 9 7.87444 9C8.12855 9 8.33782 9.08397 8.50224 9.25191L12 12.8244L15.4978 9.25191C15.6622 9.08397 15.8714 9 16.1256 9C16.3797 9 16.5889 9.08397 16.7534 9.25191C16.9178 9.41985 17 9.63359 17 9.89313C17 10.1527 16.9178 10.3664 16.7534 10.5344Z"
    fill="white"
  />
</svg>
