<h1 class="text-center lg:text-left" data-cy="title">
  VTHO-VET Swaps on Autopilot
</h1>
<p
  class="text-sm md:text-base font-normal text-center lg:text-left mt-1 md:mt-2"
  data-cy="description"
>
  Vearn streamlines the process of converting VTHO to VET tokens by
  automatically executing swaps at strategic intervals. Enjoy peace of mind as
  Vearn boosts your VET balance without any manual effort required.
</p>
